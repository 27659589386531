import React, { Suspense, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import CoachDashboard from "../Pages/CoachDashboard/CoachDashboard";
import CoachDetail from "../Pages/CoachDetail/CoachDetail";
import SessionList from "../Pages/SessionList/SessionList";
import SessionDetail from "../Pages/SessionDetail/SessionDetail";
import SessionForm from "../Pages/SessionList/SessionForm";
import ClientList from "../Pages/ClientList/ClientList";
import ClientDetail from "../Pages/ClientDetail/ClientDetail";
import ClientForm from "../Pages/AdminClientList/AdminClientForm";
import SalesList from "../Pages/SalesList/SalesList";
import SaleDetail from "../Pages/SaleDetail/SaleDetail";
import SaleForm from "../Pages/SalesList/SaleForm";
import BlogsList from "../Pages/BlogsList/BlogsList";
import BlogDetail from "../Pages/BlogDetail/BlogDetail";
import BlogForm from "../Pages/BlogsList/BlogForm";
import EventList from "../Pages/EventList/EventList";
import ClubRunDetail from "../Pages/ClubRunDetail/ClubRunDetail";
import EventForm from "../Pages/EventList/EventForm";
import Payment from "../Pages/Payment/Payment";
import AdminClientList from "../Pages/AdminClientList/AdminClientList";
import AdminCoachList from "../Pages/AdminCoachList/AdminCoachList";
import AdminCoachForm from "../Pages/AdminCoachList/AdminCoachForm";
import AdminClientDetail from "../Pages/AdminClientDetail/AdminClientDetail";
import PaymentAdjustmentForm from "../Pages/PaymentAdjustmentList/PaymentAdjustmentForm";
import PaymentAdjustmentList from "../Pages/PaymentAdjustmentList/PaymentAdjustmentList";
import PaymentAdjustmentDetail from "../Pages/PaymentAdjustmentDetail/PaymentAdjustmentDetail";
import AdminExpensesPage from "../Pages/AdminExpensesPage/AdminExpensesPage";
import AdminPayrollBreakdown from "../Pages/AdminPayrollBreakdown/AdminPayrollBreakdown";
import { NotFound } from "./NotFound";
import routingConstants from "./constants";
import config from "../config";
import AdminSalesByCoach from "../Pages/AdminSalesByCoach/AdminSalesByCoach";
import AdminSessionsByCoach from "../Pages/AdminSessionsByCoach/AdminSessionsByCoach";
import AdminClientSessions from "../Pages/AdminSessionsByClient/AdminSessionsByClient";
import SessionsByClient from "../Pages/SessionsByClient/SessionsByClient";
import AdminClientsByCoach from "../Pages/AdminClientsByCoach/AdminClientsByCoach";
import AdminClientsByState from "../Pages/AdminClientsByState/AdminClientsByState";
import AdminClubRunsByCoach from "../Pages/AdminClubRunsByCoach/AdminClubRunsByCoach";
import AdminExpiringSubscriptions from "../Pages/AdminExpiringSubscriptions/AdminExpiringSubscriptions";
import AdminSessionInactivity from "../Pages/AdminSessionInactivity/AdminSessionInactivity";
import AdminMonthlyPayments from "../Pages/AdminMonthlyPayments/AdminMonthlyPayments";
import CoachMonthlyPayments from "../Pages/CoachesMonthlyPayments/CoachesMonthlyPayments";
import ResourceCenter from "../Pages/ResourceCenter/ResourceCenter";
import SalesHoursList from "../Pages/SalesHoursList/SalesHoursList";
import SalesHoursForm from "../Pages/SalesHoursList/SalesHoursForm";
import SalesHoursDetail from "../Pages/SalesHoursDetail/SalesHoursDetail";
import AdminSalesHoursList from "../Pages/AdminSalesHours/AdminSalesHoursList";
import ReimbursementsList from "../Pages/ReimbursementList/ReimbursementList";
import ReimbursementForm from "../Pages/ReimbursementList/ReimbursementForm";
import ReimbursementDetail from "../Pages/ReimbursementDetail/ReimbursementDetail";
import AdminReimbursementsList from "../Pages/AdminReimbursements/AdminReimbursementList";
import AdminUnloggedSessions from "../Pages/AdminUnloggedSessions/AdminUnloggedSessions";
import MissedSessionsForm from "../Pages/MissedSessions/MissedSessionsForm";
import MissedSessionsList from "../Pages/MissedSessions/MissedSessionsList";
import MissedSessionDetail from "../Pages/MissedSessionDetail/MissedSessionDetail";
import AdminMissedSessions from "../Pages/AdminMissedSessions/AdminMissedSessions";
import AdminClientAssignments from "../Pages/AdminClientAssignments/AdminClientAssignments";
import AdminClientAssignmentDetail from "../Pages/AdminClientAssignments/AdminClientAssignmentDetail";
import AdminClientAssignmentForm from "../Pages/AdminClientAssignments/AdminClientAssignmentForm";
import AdminAssignmentInsights from "../Pages/AdminAssignmentInsights/AdminAssignmentInsights";
import AdminMiscellaneousTimeList from "../Pages/AdminMiscellaneousTime/AdminMiscellaneousTimeList";
import MiscellaneousTimeList from "../Pages/MiscellaneousTimeList/MiscellaneousTimeList";
import MiscellaneousTimeDetail from "../Pages/MiscellaneousTimeDetail/MiscellaneousTimeDetail";
import MiscellaneousTimeForm from "../Pages/MiscellaneousTimeList/MiscellaneousTimeForm";
import AdminAssignmentsByLength from "../Pages/AdminAssignmentsByLength/AdminAssignmentsByLength";
import MyExpiringSubscriptions from "../Pages/ExpiringSubscriptions/ExpiringSubscriptionsList";
import ProgramList from "../Pages/AdminProgramManagement/ProgramList";
import ProgramForm from "../Pages/AdminProgramManagement/ProgramForm";
import ProgramDetail from "../Pages/AdminProgramManagement/ProgramDetail";
import SessionCommissionList from "../Pages/AdminSessionCommissionManagement/SessionCommissionList";
import SessionCommissionForm from "../Pages/AdminSessionCommissionManagement/SessionCommissionForm";
import SessionCommissionDetail from "../Pages/AdminSessionCommissionManagement/SessionCommissionDetail";
import SalesHoursCommissionList from "../Pages/AdminSalesHoursManagement/AdminSalesHourlyRateList";
import SalesHoursCommissionForm from "../Pages/AdminSalesHoursManagement/AdminSalesHourlyRateForm";
import SalesHoursCommissionDetail from "../Pages/AdminSalesHoursManagement/AdminSalesHoursDetail";
import ClientNotesForm from "../Pages/ClientDetail/ClientNotesForm";
import ClientNotesDetail from "../Pages/ClientDetail/ClientNotesDetail";
import SalesCommissionList from "../Pages/AdminSalesCommissionManagement/AdminSalesCommissionList";
import SalesCommissionDetail from "../Pages/AdminSalesCommissionManagement/AdminSalesCommissionDetail";
import SalesCommissionForm from "../Pages/AdminSalesCommissionManagement/AdminSalesCommissionForm";
import AdminRevenueByState from "../Pages/AdminRevenueByState/AdminRevenueByState";

// const Overview = lazy(() => import('../Pages').then((module) => ({ default: module.Overview })));

const oktaAuth = new OktaAuth(config.oidc);

const RouterConfig = () => {
  const [open, setOpen] = useState(false);
  let location = useLocation();
  const history = useHistory();
  const customAuthHandler = (_oktaAuth, originalUri) => {
    // console.log("customAuthHandler being called")
    // console.log(oktaAuth)
    // history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    history.push("/login");
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/login`
    });
  };

  const onAuthResume = async () => {
    // console.log("onAuthResume is being called")
    history.push("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
      {location.pathname != "/login" &&
        location.pathname != "/signup" &&
        location.pathname != "/forgotpassword" && (
          <>
            <Sidebar open={open} setOpen={setOpen} click={handleClick} />
            <Navbar open={handleClick} />
          </>
        )}
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={routingConstants.LOGIN} component={Login} />
          <Route
            path={routingConstants.CALL_BACK}
            render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />}
          />
          <Route path={routingConstants.SIGNUP} exact component={Signup} />
          <Route path={routingConstants.FORGOT_PASSWORD} exact component={ForgotPassword} />
          <SecureRoute path={routingConstants.OVERVIEW} exact component={CoachDashboard} />
          <SecureRoute path={routingConstants.COACH_DETAIL} exact component={CoachDetail} />
          <SecureRoute path={routingConstants.SESSIONS} exact component={SessionList} />
          <SecureRoute path={routingConstants.SESSION_DETAIL} exact component={SessionDetail} />
          <SecureRoute path={routingConstants.SESSION_ADD} exact component={SessionForm} />
          <SecureRoute path={routingConstants.CLIENTS} exact component={ClientList} />
          <SecureRoute path={routingConstants.SALES} exact component={SalesList} />
          <SecureRoute path={routingConstants.BLOGS} exact component={BlogsList} />
          <SecureRoute path={routingConstants.BLOG_DETAIL} exact component={BlogDetail} />
          <SecureRoute path={routingConstants.BLOG_ADD} exact component={BlogForm} />
          <SecureRoute path={routingConstants.EVENTS} exact component={EventList} />
          <SecureRoute path={routingConstants.EVENT_DETAIL} exact component={ClubRunDetail} />
          <SecureRoute path={routingConstants.EVENT_ADD} exact component={EventForm} />
          <SecureRoute path={routingConstants.SALE_DETAIL} exact component={SaleDetail} />
          <SecureRoute path={routingConstants.PAYMENT} exact component={Payment} />
          <SecureRoute path={routingConstants.CLIENT_DETAIL} exact component={ClientDetail} />
          <SecureRoute path={routingConstants.CLIENT_ADD} component={ClientForm} />
          <SecureRoute path={routingConstants.SALE_ADD} component={SaleForm} />
          <SecureRoute path={routingConstants.ALL_CLIENTS} component={AdminClientList} />
          <SecureRoute path={routingConstants.ALL_COACHES} component={AdminCoachList} />
          <SecureRoute path={routingConstants.COACH_ADD} component={AdminCoachForm} />
          <SecureRoute path={routingConstants.ADMIN_CLIENT_DETAIL} component={AdminClientDetail} />
          <SecureRoute path={routingConstants.ADMIN_SALES_BY_COACH} component={AdminSalesByCoach} />
          <SecureRoute
            path={routingConstants.COACH_MONTHLY_PAYMENTS}
            component={CoachMonthlyPayments}
          />
          <SecureRoute
            path={routingConstants.ADMIN_MONTHLY_PAYMENTS}
            component={AdminMonthlyPayments}
          />
          <SecureRoute
            path={routingConstants.ADMIN_CLIENTS_BY_COACH}
            component={AdminClientsByCoach}
          />
          <SecureRoute
            path={routingConstants.ADMIN_CLIENTS_BY_STATE}
            component={AdminClientsByState}
          />
          <SecureRoute
            path={routingConstants.COACH_SESSIONS_BY_CLIENT}
            component={SessionsByClient}
          />
          <SecureRoute
            path={routingConstants.ADMIN_EVENTS_BY_COACH}
            component={AdminClubRunsByCoach}
          />
          <SecureRoute
            path={routingConstants.ADMIN_SESSIONS_BY_COACH}
            component={AdminSessionsByCoach}
          />
          <SecureRoute
            path={routingConstants.ADMIN_CLIENTS_DETAIL}
            component={AdminClientSessions}
          />
          <SecureRoute
            path={routingConstants.ADMIN_PAYMENT_ADJUSTMENTS}
            component={PaymentAdjustmentList}
          />
          <SecureRoute
            path={routingConstants.ADMIN_PAYMENT_ADJUSTMENT_DETAIL}
            component={PaymentAdjustmentDetail}
          />
          <SecureRoute
            path={routingConstants.PAYMENT_ADJUSTMENT_ADD}
            component={PaymentAdjustmentForm}
          />
          <SecureRoute path={routingConstants.ADMIN_EXPENSES} component={AdminExpensesPage} />
          <SecureRoute
            path={routingConstants.ADMIN_PAYROLL_BREAKDOWN}
            component={AdminPayrollBreakdown}
          />
          <SecureRoute
            path={routingConstants.ADMIN_EXPIRING_SUBSCRIPTIONS}
            component={AdminExpiringSubscriptions}
          />
          <SecureRoute
            path={routingConstants.ADMIN_SESSION_INACTIVITY}
            component={AdminSessionInactivity}
          />
          <SecureRoute
            path={routingConstants.RESOURCE_CENTER}
            component={ResourceCenter}
          />
          <SecureRoute
            path={routingConstants.SALES_HOURS_LIST}
            component={SalesHoursList}
          />
          <SecureRoute
            path={routingConstants.SALES_HOURS_FORM}
            component={SalesHoursForm}
          />
          <SecureRoute
            path={routingConstants.SALES_HOURS_DETAIL}
            component={SalesHoursDetail}
          />
          <SecureRoute
            path={routingConstants.ADMIN_SALES_HOURS_LIST}
            component={AdminSalesHoursList}
          />
          <SecureRoute
            path={routingConstants.REIMBURSEMENTS}
            component={ReimbursementsList}
          />
          <SecureRoute
            path={routingConstants.REIMBURSEMENT_FORM}
            component={ReimbursementForm}
          />
          <SecureRoute
            path={routingConstants.REIMBURSEMENT_DETAIL}
            component={ReimbursementDetail}
          />
          <SecureRoute
            path={routingConstants.ADMIN_REIMBURSEMENTS_LIST}
            component={AdminReimbursementsList}
          />
          <SecureRoute
            path={routingConstants.ADMIN_UNLOGGED_SESSIONS}
            component={AdminUnloggedSessions}
          />
          <SecureRoute
            path={routingConstants.MISSED_SESSION_ADD}
            component={MissedSessionsForm}
          />
          <SecureRoute
            path={routingConstants.MISSED_SESSIONS}
            component={MissedSessionsList}
          />
          <SecureRoute
            path={routingConstants.MISSED_SESSION_DETAIL}
            component={MissedSessionDetail}
          />
          <SecureRoute
            path={routingConstants.ADMIN_MISSED_SESSIONS}
            component={AdminMissedSessions}
          />
          <SecureRoute
            path={routingConstants.ADMIN_CLIENT_ASSIGNMENTS}
            component={AdminClientAssignments}
          />
          <SecureRoute
            path={routingConstants.ASSIGNMENT_DETAIL}
            component={AdminClientAssignmentDetail}
          />
          <SecureRoute
            path={routingConstants.ASSIGNMENT_ADD}
            component={AdminClientAssignmentForm}
          />
          <SecureRoute
            path={routingConstants.ASSIGNMENT_INSIGHTS}
            component={AdminAssignmentInsights}
          />
          <SecureRoute
            path={routingConstants.MISCELLANEOUS_TIME}
            component={MiscellaneousTimeList}
          />
          <SecureRoute
            path={routingConstants.MISCELLANEOUS_TIME_DETAIL}
            component={MiscellaneousTimeDetail}
          />
          <SecureRoute
            path={routingConstants.ADMIN_MISCELLANEOUS_TIME}
            component={AdminMiscellaneousTimeList}
          />
          <SecureRoute
            path={routingConstants.MISCELLANEOUS_TIME_ADD}
            component={MiscellaneousTimeForm}
          />
          <SecureRoute
            path={routingConstants.ASSIGNMENTS_BY_LENGTH}
            component={AdminAssignmentsByLength}
          />
          <SecureRoute
            path={routingConstants.MY_EXPIRING_SUBSCRIPTIONS}
            component={MyExpiringSubscriptions}
          />
          <SecureRoute
            path={routingConstants.PROGRAM_MANAGEMENT}
            component={ProgramList}
          />
          <SecureRoute
            path={routingConstants.PROGRAM_ADD}
            component={ProgramForm}
          />
          <SecureRoute
            path={routingConstants.PROGRAM_DETAIL}
            component={ProgramDetail}
          />
          <SecureRoute
            path={routingConstants.SESSION_COMMISSION_LIST}
            component={SessionCommissionList}
          />
          <SecureRoute
            path={routingConstants.ADD_SESSION_COMMISSION}
            component={SessionCommissionForm}
          />
          <SecureRoute
            path={routingConstants.SESSION_COMMISSION_DETAIL}
            component={SessionCommissionDetail}
          />
          <SecureRoute
            path={routingConstants.SALES_HOURS_COMMISSIONS}
            component={SalesHoursCommissionList}
          />
          <SecureRoute
            path={routingConstants.ADD_SALES_HOURS_COMMISSION}
            component={SalesHoursCommissionForm}
          />
          <SecureRoute
            path={routingConstants.SALES_HOURS_COMMISSION_DETAIL}
            component={SalesHoursCommissionDetail}
          />
          <SecureRoute
            path={routingConstants.CLIENT_NOTES_ADD}
            component={ClientNotesForm}
          />
          <SecureRoute
            path={routingConstants.CLIENT_NOTES_DETAIL}
            component={ClientNotesDetail}
          />
          <SecureRoute
            path={routingConstants.SALES_COMMISSION_LIST}
            component={SalesCommissionList}
          />
          <SecureRoute
            path={routingConstants.SALES_COMMISSION_DETAIL}
            component={SalesCommissionDetail}
          />
          <SecureRoute
            path={routingConstants.SALES_COMMISSION_ADD}
            component={SalesCommissionForm}
          />
          <SecureRoute
            path={routingConstants.ADMIN_REVENUE_BY_STATE}
            component={AdminRevenueByState}
          />
          <SecureRoute component={NotFound} />
        </Switch>
      </Suspense>
    </Security>
  );
};

export default RouterConfig;
