import ReactSelect from "./ReactSelect.js";
import React, { useState } from "react";
import FormSelect from "./FormSelect.js";
import {
  desiredCoachExperienceLevelOptions,
  energyLevelPreferenceOptions,
  experienceList,
  faithBasedOptions,
  lessonsImportanceOptions,
  personalityTypeList,
  sportOptions,
  stateList,
  trainingLevelList,
  rpInvolvementOptions,
  salesSourceList,
  genderOptionsList,
  tzList
} from "../../Utils/selectConstants.js";
import insertSignUpData from "../../Utils/insertSignUpData.js";
import { Button, Col, Form, Row } from "react-bootstrap";

const NewAthleteForm = ({
  firstName,
  lastName,
  nickname,
  gender,
  email,
  phone,
  address,
  city,
  state,
  trainingLevel,
  school,
  club,
  experience,
  personalityType,
  faithBased,
  goals,
  aspirations,
  additionalInfo,
  lessonsImportance,
  dateOfBirth,
  rpName,
  rpPhone,
  rpEmail,
  rpRelation,
  rpInvolvement,
  teamName,
  profession,
  sports,
  salesSource,
  salesSourceDetailsLabel,
  salesSourceDetails,
  energyLevelPreference,
  desiredCoachExperienceLevel,
  timezone,
  preferredSessionAvailability,
  handleChange,
  clientType,
  reset_data,
  setClientType
}) => {
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      // do nothing
    } else {
      insertSignUpData(req_obj, clientType, reset_data);
    }

    setValidated(true);
  };

  const req_obj = {
    is_active: 1,
    client_type: clientType.value,
    first_name: firstName,
    last_name: lastName,
    nickname: nickname,
    gender: gender.value,
    team_name: teamName,
    email: email,
    phone: phone,
    address: address,
    city: city,
    profession: profession,
    address_state: state.value,
    training_level: trainingLevel.value,
    school: school,
    club: club,
    experience: experience.value,
    personality_type: personalityType.value,
    date_of_birth: dateOfBirth,
    responsible_person_name: rpName,
    responsible_person_phone: rpPhone,
    responsible_person_email: rpEmail,
    responsible_person_relationship: rpRelation,
    responsible_person_involvement: rpInvolvement.value,
    faith_based: faithBased.value,
    goals: goals,
    other_aspirations: aspirations,
    additional_info: additionalInfo,
    energy_level_preference: energyLevelPreference.value,
    desired_experience_of_coach: desiredCoachExperienceLevel.value,
    sports: sports.map((data) => data.value),
    importance_of_lessons: lessonsImportance.value,
    sales_source: salesSource.value,
    sales_source_details: salesSourceDetails,
    primary_time_zone: timezone.value,
    preferred_session_availability: preferredSessionAvailability
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3 mt-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            value={firstName}
            placeholder="First Name"
            isInvalid={!/^[A-Za-z0-9_ -]+$/.test(firstName) && firstName.length > 0}
            onChange={(e) => handleChange("First Name", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a First Name without special characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Last Name"
            value={lastName}
            isInvalid={!/^[A-Za-z0-9_ -]+$/.test(firstName) && firstName.length > 0}
            onChange={(e) => handleChange("Last Name", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a First Name without special characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label style={{ color: "white" }}>Nickname</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nickname"
            value={nickname}
            onChange={(e) => handleChange("Nickname", e)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className="mb-2">
          <Form.Label style={{ color: "white" }}>Gender</Form.Label>
          <FormSelect
            defaultVal="Select a Gender"
            selector="Gender"
            options={genderOptionsList}
            value={gender.value}
            required={true}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please select a Gender.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Email Address</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Email Address"
            value={email}
            onChange={(e) => handleChange("Email", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Phone number</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Phone number"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            onChange={(e) => handleChange("Phone", e)}
            value={phone}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid phone number in dashed format. Ex: 888-888-8888
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3 mt-3">
        <Form.Group as={Col} md="3" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Street Address</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Street Address"
            value={address}
            onChange={(e) => handleChange("Street Address", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Street Address.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>City/Town</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="City/Town"
            value={city}
            onChange={(e) => handleChange("City/Town", e)}
          />
          <Form.Control.Feedback type="invalid">Please enter your City/Town.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label style={{ color: "white" }}>State</Form.Label>
          <FormSelect
            defaultVal="Select a State"
            selector="State"
            options={stateList}
            required={true}
            value={state.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please select a State.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3">
          <Form.Label style={{ color: "white" }}>Timezone</Form.Label>
          <FormSelect
            defaultVal="Timezone"
            selector="Primary Time Zone"
            options={tzList}
            required={true}
            value={timezone.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please select a Timezone.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="6">
          <ReactSelect
            options={sportOptions}
            value={sports}
            placeholder="Primary Sport(s)"
            handleChange={handleChange}
            isMulti
          />
          <Form.Control.Feedback type="invalid">Please select a Sport.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label style={{ color: "white" }}>Training Level</Form.Label>
          <FormSelect
            defaultVal="Select a Training Level"
            value={trainingLevel.value}
            options={trainingLevelList}
            selector="Training Level"
            required={true}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Please select a Training Level.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>School Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="School Name"
            value={school}
            isInvalid={!/^[A-Za-z0-9_ -]+$/.test(firstName) && firstName.length > 0}
            onChange={(e) => handleChange("School Name", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a School Name without special characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Club Name(s), if none put N/A.</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Club Name(s)"
            value={club}
            isInvalid={!/^[A-Za-z0-9_ -]+$/.test(firstName) && firstName.length > 0}
            onChange={(e) => handleChange("Club Name(s)", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter Club Name(s) without special characters.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Experience Level</Form.Label>
          <FormSelect
            defaultVal="Select an Experience Level"
            options={experienceList}
            required={true}
            value={experience.value}
            selector="Experience"
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Please select an Experience Level.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Personality Type</Form.Label>
          <FormSelect
            defaultVal="Select a Personality Type"
            options={personalityTypeList}
            required={true}
            selector="Personality Type"
            value={personalityType.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Please select a Personality Type.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label style={{ color: "white" }}>
            Would you like to incorporate the Christian faith into your training?
          </Form.Label>
          <FormSelect
            defaultVal="Select One"
            options={faithBasedOptions}
            required={true}
            selector="Would you like to incorporate the Christian faith into your training?"
            value={faithBased.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please make a selection.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Goals</Form.Label>
          <Form.Control
            required
            as="textarea"
            placeholder="Goals"
            rows={5}
            value={goals}
            onChange={(e) => handleChange("Goals", e)}
          />
          <Form.Control.Feedback type="invalid">Please enter your goals.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Aspirations outside of Athletics</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            value={aspirations}
            onChange={(e) => handleChange("Aspirations outside of athletics", e)}
          />
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label style={{ color: "white" }}>
            Do you prefer a Mindset Coach with low or high energy?
          </Form.Label>
          <FormSelect
            defaultVal="Energy Level Preference"
            options={energyLevelPreferenceOptions}
            required={true}
            selector="Do you prefer a Mindset Coach with low or high energy?"
            value={energyLevelPreference.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please make a selection.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label style={{ color: "white" }}>Desired Age Range</Form.Label>
          <FormSelect
            defaultVal="Select One"
            options={desiredCoachExperienceLevelOptions}
            required={true}
            selector="Desired age range of assigned Mindset Coach"
            value={desiredCoachExperienceLevel.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please make a selection.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>
            Any additional info that will help match you with a Mindset Coach
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={additionalInfo}
            onChange={(e) =>
              handleChange("Additional info that will help match you with a Mindset Coach", e)
            }
          />
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Preferred Days and Windows of Time</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={3}
            placeholder="We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?"
            value={preferredSessionAvailability}
            onChange={(e) =>
              handleChange(
                "We can't guarantee exact days and times before you meet with your coach, but what are your preferred days and windows of time for mindset sessions to take place?",
                e
              )
            }
          />
          <Form.Control.Feedback type="invalid">
            Please enter any additional info.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label style={{ color: "white" }}>
            Importance of learning life lessons in the program.
          </Form.Label>
          <FormSelect
            defaultVal="Select One"
            options={lessonsImportanceOptions}
            required={true}
            selector="Importance of learning life lessons in the program"
            value={lessonsImportance.value}
            handleChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">Please make a selection.</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Date of Birth</Form.Label>
          <Form.Control
            required
            type="date"
            placeholder="Date of Birth"
            onChange={(e) => handleChange("Date of Birth", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Date of Birth.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Responsible Person Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Responsible Person Name"
            value={rpName}
            onChange={(e) => handleChange("Responsible Person Name", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Responbile Person Name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Responsible Person Phone</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Responsible Person Phone"
            value={rpPhone}
            onChange={(e) => handleChange("Responsible Person Phone", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Responsible Person Phone.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Responsible Person Email</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="Responsible Person Email"
            value={rpEmail}
            onChange={(e) => handleChange("Responsible Person Email", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter a valid email address.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Responsible Person Relationship</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Responsible Person Relationship"
            value={rpRelation}
            onChange={(e) => handleChange("Responsible Person Relationship", e)}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Responsible Person Relationship.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label style={{ color: "white" }}>Responsible Person Involvement</Form.Label>
          <FormSelect
            defaultVal="Select One"
            required={true}
            options={rpInvolvementOptions}
            selector="Responsible Person Involvement"
            handleChange={handleChange}
            value={rpInvolvement.value}
          />
          <Form.Control.Feedback type="invalid">
            Please enter your Responsible Person Involvement.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label style={{ color: "white" }}>
            How did you hear about Winning Mindset?
          </Form.Label>
          <FormSelect
            options={salesSourceList}
            selector="How did you hear about Winning Mindset?"
            value={salesSource.value}
            required={true}
            handleChange={handleChange}
          />
        </Form.Group>
      </Row>
      {salesSource.value === "Team/Club Presentation" ||
      salesSource.value === "Convention" ||
      salesSource.value == "Referral" ||
      salesSource.value == "Social Media" ||
      salesSource.value == "Tournament" ||
      salesSource.value == "Mindset Coach" ? (
        <Row className="mt-3">
          <Form.Group as={Col} controlId="validationCustom01">
            <Form.Label style={{ color: "white" }}>Source Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter more details on how you found Winning Mindset"
              onChange={(e) => handleChange(salesSourceDetailsLabel, e)}
            />
            <Form.Control.Feedback type="invalid">Please enter your details.</Form.Control.Feedback>
          </Form.Group>
        </Row>
      ) : (
        <div></div>
      )}
      <Button className="mt-3" type="submit">
        Save
      </Button>
    </Form>
  );
};

export default NewAthleteForm;
