import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { adminAction, sessionAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { insert_data } from "../../Utils/insertData";
import { months, years } from "../../Utils/arrayItems";
import { clientStatuses } from '../../Utils/selectConstants';

const SalesHoursCommissionList = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.authReducer.persona);
  const salesHoursCommissionList_db = useSelector(
    (state) => state.adminReducer.adminSalesHourlyCommissions.result
  );

  const exportData = useSelector(
    (state) => state.adminReducer.adminSalesHourlyCommissions.full
  );

  const salesHoursCommissionListLoaded = useSelector(
    (state) => state.adminReducer.adminSalesHourlyCommissionsLoaded
  );
  const salesReps_db = useSelector((state) => state.adminReducer.allSalesReps);
  const default_filters = useSelector((state) => state.filterReducer.filterData);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [showToggle, setShowToggle] = useState(false);
  const [salesRepsSelected, setSalesRepsSelected] = useState([]);
  const [statusesSelected, setStatusesSelected] = useState([{ value: "Active", label: "Active" }]);
  const [saleRepList, setSalesRepList] = useState({ value: "", label: "" });

  useEffect(() => {
    dispatch(adminAction.loadAllSalesReps({ filters: { is_active: [statusesSelected[0].value] } }));
    dispatch(
      filterAction.loadSavedUserFilters({ filters: { page_name: "Sales Hours Commission Management" } })
    );
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = salesRepsSelected.map((coach) => coach.value);
      let status_arr = statusesSelected.map((data) => data.value);
      let filtersObj = {
        coaches: coaches_arr,
        is_active: status_arr,
        page_name: "Sales Hours Commission Management"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    if (salesReps_db.length > 0) {
      let sr_list_db = salesReps_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "All", label: "All" });
      setSalesRepList(sr_list_db);
    }
  }, [salesReps_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminSalesHourlyCommissions({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setSalesRepsSelected(temp_array);
        } else if (key == "is_active") {
          setStatusesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminSalesHourlyCommissions({ filters: filters }));
    }
  }, [savedUserFilters]);

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Coaches":
        if (e.length == 0) {
          setSalesRepsSelected([{ value: "All", label: "All" }]);
        } else {
          setSalesRepsSelected(newVals);
        }
        break;
      case "Status":
        if (e.length == 0) {
          setStatusesSelected([{ value: "All", label: "All" }]);
        } else {
          setStatusesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllSalesReps({ filters: { is_active: [statusesSelected[0].value] } }));
  }, [statusesSelected]);

  useEffect(() => {
    dispatch(adminAction.loadAdminSalesHourlyCommissions({ filters: filters }));
  }, [applyClicked]);

  return (
    <AccordionContainer
      values={salesHoursCommissionList_db}
      filtersClicked={filtersClicked}
      setFiltersClicked={setFiltersClicked}
      title="SALES HOURS COMMISSION MANAGEMENT"
      type="SALES HOURS COMMISSIONS"
      headers={["coach_name", "rate", "sales_hours_commission_start_date", "sales_hours_commission_end_date"]}
      row_id="sales_hours_commission_id"
      linkName="sales_hours_commission"
      pathName={"sales_hours_commission_detail"}
      loaded={salesHoursCommissionListLoaded}
      buttonTitle="ADD AN HOURLY COMMISSION RATE"
      admin={userInfo.is_admin === "1"}
      exportButton={true}
      exportData={exportData}
      handleClick={handleClick}>
      <ReactSelect
        options={clientStatuses}
        value={statusesSelected}
        isMulti={true}
        placeholder="Status"
        handleChange={handleChange}
        id={1}
      />
      <ReactSelect
        options={saleRepList}
        value={salesRepsSelected}
        isMulti={true}
        placeholder="Coaches"
        handleChange={handleChange}
        id={2}
      />
    </AccordionContainer>
  );
};

export default SalesHoursCommissionList;
