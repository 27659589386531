import axios from "axios";
import { toast } from "react-toastify";
import API_CONSTANTS from "../Redux/constants";

const requestOptions = (reqObj) => ({
  method: reqObj.method,
  url: reqObj.url,
  data: reqObj.data,
  headers: {
    Authorization: window.location.toString().split("key=")[1],
    "Content-Type": "application/json"
  },
  cancelToken: reqObj.token ? reqObj.token : ""
});

export default function insertSignUpData(req_obj, type, reset) {
  if (
    type.value == "Athlete" ||
    type.value == "Team" ||
    type.value === "Corporate" ||
    type.value === "Test"
  ) {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.ADD_CLIENT_NEW, data: req_obj }))
      .then((res) => {
        if (res.data.status == 'Success') {
          toast.success(res.data.message, {
            position: "top-center"
          });
          reset();
        } else {
          toast.error(res.data.message, {
            position: "top-center"
          });
        }
      })
      .catch((err) => {
        toast.error("There was an error inserting form data. Please contact the Winning Mindset team for assistance.", {
          position: "top-center"
        });
      });
  } else if (type.value == "Mindset Coach") {
    axios(requestOptions({ method: "POST", url: API_CONSTANTS.ADD_COACH_NEW, data: req_obj }))
      .then((res) => {
        if (res) {
          if (res.data.message === "Success") {
            reset();
          } else if (res.data.message === "Duplicates") {
            toast.warning(
              "Entered info matches a coach that already exists in the database. Please contact an admin.",
              { position: "top-center" }
            );
          }
        } else {
          toast.error("There was an error signing up as a new coach", {
            position: "top-center"
          });
        }
      })
      .catch((err) => {
        toast.error("There was an error inserting new coach data", {
          position: "top-center"
        });
      });
  }
}
