import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "react-bootstrap";

const FormContainer = ({ children, onClick, title, style = {}, buttons = true }) => {
  return (
    <div className="main-container mt-5">
      <p className="form-title">{title}</p>
      <div className="form-container" style={style}>
        {children &&
          children.map((child) => {
            return (
              <div key={"test"} className="item">
                {child}
              </div>
            );
          })}
        {buttons ? (
          <Row>
            <Col>
              <Button id={"reset"} className="add mt-3" onClick={onClick}>
                RESET
              </Button>
            </Col>
            <Col>
              <Button id={"add"} className="add mt-3" onClick={onClick}>
                ADD
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FormContainer;
