import React from "react";
import Select, { components } from "react-select";
import { styles, theme } from "../../Utils/selectStyles";

const { Placeholder, ValueContainer } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const ReactSelect = ({ handleChange, options, value, placeholder, isMulti }) => {
  return (
    <Select
      options={options}
      value={value}
      isMulti={isMulti}
      styles={styles}
      theme={theme}
      placeholder={placeholder}
      components={{
        ValueContainer: CustomValueContainer
      }}
      onChange={(e) => handleChange(placeholder, e, true)}
      className="react-select"
    />
  );
};

export default ReactSelect;
