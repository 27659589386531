import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccordionContainer from "../../Components/ListViews/AccordionContainer";
import { adminAction, filterAction } from "../../Redux/actions";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { insert_data } from "../../Utils/insertData";

const AdminSessionInactivity = (props) => {
  const dispatch = useDispatch();
  const clientAssignmentList = useSelector((state) => state.adminReducer.adminSessionInactivity.result);
  const exportData = useSelector((state) => state.adminReducer.adminSessionInactivity.export);
  const clientAssignmentListLoaded = useSelector((state) => state.adminReducer.adminSessionInactivityLoaded);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const coachList = useSelector((state) => state.adminReducer.allCoaches);
  const [coachOptions, setCoachOptions] = useState({ value: "", label: "" });
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [clientTypesSelected, setClientTypesSelected] = useState([
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ]);
  const [coachesSelected, setCoachesSelected] = useState([{ value: "All", label: "All" }]);
  const [filters, setFilters] = useState({
    clientType: ["Athlete", "Team"],
    coaches: ["All"]
  });

  useEffect(() => {
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Session Inactivity" } }));
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
  }, []);

  useEffect(() => {
    if (coachList.length > 0) {
      let sr_list_db = coachList.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.unshift({ value: "All", label: "All" });
      setCoachOptions(sr_list_db);
    }
  }, [coachList]);

  const handleChange = (selector, e) => {
    let newVals = e.filter((item) => item.value != "All");
    switch (selector) {
      case "Client Type":
        if (e.length == 0) {
          setClientTypesSelected([
            { value: "Athlete", label: "Athlete" },
            { value: "Team", label: "Team" }
          ]);
        } else {
          setClientTypesSelected(e);
        }
        break;
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminSessionInactivity({ filters: savedUserFilters }));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "clientType") {
          setClientTypesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminSessionInactivity({ filters: filters }));
    }
  }, [savedUserFilters]);

  const clientTypes = [
    { value: "Athlete", label: "Athlete" },
    { value: "Team", label: "Team" }
  ];

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let client_type_arr = clientTypesSelected.map((data) => data.value);
      let coach_arr = coachesSelected.map((data) => data.value);
      let filtersObj = {
        clientType: client_type_arr,
        coaches: coach_arr,
        page_name: "Session Inactivity"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAdminSessionInactivity({ filters: filters }));
  }, [applyClicked]);

  return (
    <>
      <AccordionContainer
        values={clientAssignmentList}
        filtersClicked={filtersClicked}
        setFiltersClicked={setFiltersClicked}
        title="SESSION INACTIVITY BY COACH"
        type="SESSIONS"
        headers={["client_name", "last_session"]}
        row_id="client_id"
        admin={true}
        loaded={clientAssignmentListLoaded}
        handleClick={handleClick}
        exportButton={true}
        exportData={exportData}>
        <ReactSelect
          options={coachOptions}
          value={coachesSelected}
          isMulti={true}
          placeholder="Coaches"
          handleChange={handleChange}
        />
        <ReactSelect
          options={clientTypes}
          value={clientTypesSelected}
          isMulti={true}
          placeholder="Client Type"
          handleChange={handleChange}
        />
      </AccordionContainer>
    </>
  );
};

export default AdminSessionInactivity;
