import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PersonCircle, Clock, Calendar } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "./Components.scss";

const SessionsCard = ({ id, session }) => {
  return (
    <Container>
      <Link
        className="sessionCardLink"
        key={id}
        to={{ pathname: "/session_detail", state: { id: id } }}>
        <Row className="session-card mt-2">
          <Col xs={12}>
            <PersonCircle size={30} color="black" />
          </Col>
          <Col xs={12}>
            <p>{session.client_name && session.client_name.length > 10 ? session.client_name.slice(0,10) + '...': session.client_name}</p>
          </Col>
          <div style={{ paddingLeft: 0, paddingRight: 0 }}>
            <div className="time">
              <Clock size={20} />
              <p>{session.session_type}</p>
            </div>
            <div className="time">
              <Calendar size={20} />
              <p>{session.session_date}</p>
            </div>
          </div>
        </Row>
      </Link>
    </Container>
  );
};

export default SessionsCard;
