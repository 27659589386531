import React, { useEffect, useState } from "react";
import { Table, Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../../Components/Filters";
import ReactSelect from "../../Components/Forms/ReactSelect";
import { adminAction } from "../../Redux/actions";
import { filterAction } from "../../Redux/actions";
import { months, years } from "../../Utils/arrayItems";
import { insert_data } from "../../Utils/insertData";
import { keys } from "../../Utils/keysForSalesReport";
import { salesSourceList } from "../../Utils/selectConstants";
import { total_sum_data } from "../../Utils/totalSumData";
import DatePicker from "../../Components/Forms/DatePicker";
import { DateRangePicker } from "react-date-range";
import { TailSpin } from "react-loader-spinner";
import { states } from "../../Utils/arrayItems";

const AdminPayrollBreakdownPage = (props) => {
  const dispatch = useDispatch();
  const coachList_db = useSelector((state) => state.adminReducer.allCoaches);
  let payroll_breakdown = useSelector((state) => state.adminReducer.adminPayrollBreakdown);
  const payrollBreakdownLoaded = useSelector((state) => state.adminReducer.adminPayrollBreakdownLoaded);
  const savedUserFilters = useSelector((state) => state.filterReducer.savedUserFilters);
  const [data, setData] = useState(payroll_breakdown);
  const [filtersClicked, setFiltersClicked] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [filters, setFilters] = useState({});
  const [coachesSelected, setCoachesSelected] = useState([]);
  const [salesSourcesSelected, setSalesSourcesSelected] = useState([]);
  const [coachList, setCoachList] = useState({ value: "", label: "" });
  const [salesSourceOptions, setSalesSourceOptions] = useState({ value: "", label: "" });
  const [sumData, setSumData] = useState([]);
  const [sortingDirection, setSortingDirection] = useState(keys);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showToggle, setShowToggle] = useState(false);
  const [statesSelected, setStatesSelected] = useState([{value: "All", label: "All"}]);

  const sortColumn = (column, type) => {
    const direction = sortingDirection[column];
    if (direction === "ASC" || direction === "") {
      const sortedData = [...payroll_breakdown].sort((a, b) => (a[column] < b[column] ? 1 : -1));
      const sortedSumData = [...sumData].sort((a, b) => (a[column] < b[column] ? 1 : -1));
      if (type == "data") {
        setData(sortedData);
      } else {
        setSumData(sortedSumData);
      }
      setSortingDirection({ ...sortingDirection, [column]: "DESC" });
    } else if (direction === "DESC") {
      const sortedData = [...payroll_breakdown].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      const sortedSumData = [...sumData].sort((a, b) => (a[column] > b[column] ? 1 : -1));
      if (type == "data") {
        setData(sortedData);
      } else {
        setSumData(sortedSumData);
      }
      setSortingDirection({ ...sortingDirection, [column]: "ASC" });
    }
  };

  useEffect(() => {
    dispatch(adminAction.loadAllCoaches({ filters: { is_active: ["Active"] } }));
    dispatch(filterAction.loadSavedUserFilters({ filters: { page_name: "Payroll Breakdown" } }));
    salesSourceList.push({ value: "All", label: "All" });
    setSalesSourceOptions(salesSourceList);
  }, []);

  const handleClick = (e) => {
    if (e.target.id == "apply") {
      let coaches_arr = coachesSelected.map((coach) => coach.value);
      let states_arr = statesSelected.map((state) => state.value);
      let sales_sources_arr = salesSourcesSelected.map((sales_source) => sales_source.value);
      let filtersObj = {
        coaches: coaches_arr,
        sales_sources: sales_sources_arr,
        start_date: [startDate],
        end_date: [endDate],
        address_state: states_arr,
        page_name: "Payroll Breakdown"
      };
      setFilters(filtersObj);
      setFiltersClicked(!filtersClicked);
      setApplyClicked(!applyClicked);
      insert_data(filtersObj);
    } else {
      setFiltersClicked(!filtersClicked);
    }
  };

  const handleChange = (selector, e) => {
    let newVals = [];
    if (selector != "Date Range") {
      newVals = e.filter((item) => item.value != "All");
    }
    let currentDate = new Date().toLocaleString("default", { month: "long" });
    let currentYear = new Date().getFullYear().toString();
    const end = e.length - 1;
    switch (selector) {
      case "Date Range":
        setStartDate(new Date(e.selection.startDate).toLocaleDateString());
        setEndDate(new Date(e.selection.endDate).toLocaleDateString());
        break;
      case "Coaches":
        if (e.length == 0) {
          setCoachesSelected([{ value: "All", label: "All" }]);
        } else {
          setCoachesSelected(newVals);
        }
        break;
      case "State":
        if (e.length == 0) {
          setStatesSelected([{ value: "All", label: "All" }]);
        } else {
          setStatesSelected(newVals);
        }
        break;
      case "Sales Sources":
        if (e.length == 0) {
          setSalesSourcesSelected([{ value: "All", label: "All" }]);
        } else {
          setSalesSourcesSelected(newVals);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setData(payroll_breakdown);
    setSumData(total_sum_data(payroll_breakdown));
  }, [payroll_breakdown]);

  const grand_totals = (data) => {};

  useEffect(() => {
    if (coachList_db.length > 0) {
      let sr_list_db = coachList_db.map((coach) => {
        return {
          value: `${coach.full_name} (${coach.coach_id})`,
          label: `${coach.full_name} (${coach.coach_id})`
        };
      });
      sr_list_db.push({ value: "All", label: "All" });
      setCoachList(sr_list_db);
    }
  }, [coachList_db]);

  useEffect(() => {
    if (Object.keys(savedUserFilters).length > 0) {
      dispatch(adminAction.loadAdminPayrollBreakdown({ filters: savedUserFilters }));
      setSumData(total_sum_data(data));
      let temp_array = [];
      for (const [key, value] of Object.entries(savedUserFilters)) {
        let temp_array = savedUserFilters[key].map((data) => {
          return {
            value: data,
            label: data
          };
        });
        if (key == "start_date") {
          setStartDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "end_date") {
          setEndDate(new Date(savedUserFilters[key][0]).toLocaleDateString());
        } else if (key == "coaches") {
          setCoachesSelected(temp_array);
        } else if (key == "sales_sources") {
          setSalesSourcesSelected(temp_array);
        }
      }
      temp_array.length = 0;
    } else {
      dispatch(adminAction.loadAdminPayrollBreakdown({ filters: filters }));
      grand_totals();
    }
  }, [savedUserFilters]);

  useEffect(() => {
    dispatch(adminAction.loadAdminPayrollBreakdown({ filters: filters }));
  }, [applyClicked]);

  return (
    <div className="expenses-container">
      <Row className="mt-4">
        <div className="title-row">
          <div className="expenses-title">REVENUE & PAYROLL BREAKDOWN</div>
          <Button
            className="filters-button-client"
            onClick={() => setFiltersClicked(!filtersClicked)}>
            FILTERS
          </Button>
        </div>
      </Row>
      {filtersClicked && (
        <Filters
          setFiltersClicked={setFiltersClicked}
          filtersClicked={filtersClicked}
          onClick={handleClick}>
          <DatePicker
            label={"Date Range"}
            startDate={startDate}
            endDate={endDate}
            showToggle={showToggle}
            setShowToggle={setShowToggle}
            type="range">
            <DateRangePicker
              startDatePlaceholder={startDate}
              endDatePlaceholder={endDate}
              ranges={[
                { startDate: new Date(startDate), endDate: new Date(endDate), key: "selection" }
              ]}
              onChange={(e) => handleChange("Date Range", e)}
              showPreview={false}
            />
          </DatePicker>
          <ReactSelect
            options={states}
            value={statesSelected}
            isMulti={true}
            placeholder="State"
            handleChange={handleChange}
          />
          <ReactSelect
            options={coachList}
            value={coachesSelected}
            isMulti={true}
            placeholder="Coaches"
            handleChange={handleChange}
          />
          <ReactSelect
            options={salesSourceOptions}
            value={salesSourcesSelected}
            isMulti={true}
            placeholder="Sales Sources"
            handleChange={handleChange}
          />
        </Filters>
      )}
      {!payrollBreakdownLoaded && (
          <div style={{ margin: 'auto' }}>
              <TailSpin
                visible={true}
                height="60"
                width="100%"
                color="#f1eb42e9"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              />
          </div>
        )
      }  
      {payrollBreakdownLoaded && (<><Row className="mt-4">
        <Table striped variant="dark" className="expenses-table" responsive>
          <thead>
            <tr>
              <th onClick={() => sortColumn("initial_sale_or_renewal", "data")}>Initial/Renewal</th>
              <th onClick={() => sortColumn("recurring_or_pif", "data")}>Recurring/PIF</th>
              <th onClick={() => sortColumn("origin", "data")}>Origin</th>
              <th onClick={() => sortColumn("sales_rep", "data")}>Sales Rep</th>
              <th onClick={() => sortColumn("client_name", "data")}>Client Name</th>
              <th onClick={() => sortColumn("parent_name", "data")}>Parent Name</th>
              <th onClick={() => sortColumn("month", "data")}>Month</th>
              <th onClick={() => sortColumn("program", "data")}>Program</th>
              <th onClick={() => sortColumn("revenue", "data")}>Revenue</th>
              <th onClick={() => sortColumn("ind_commission", "data")}>Ind. Commission</th>
              <th onClick={() => sortColumn("team_commission", "data")}>Team Commission</th>
              <th onClick={() => sortColumn("profit_before_sessions", "data")}>
                Profit before Sessions
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((coach, i) => {
                return (
                  <tr key={`{${coach.sales_rep}${coach.coach_id}${i}`}>
                    <td>{coach.initial_sale_or_renewal}</td>
                    <td>{coach.recurring_or_pif}</td>
                    <td>{coach.origin}</td>
                    <td>{coach.sales_rep}</td>
                    <td>{coach.client_name}</td>
                    <td>{coach.parent_name}</td>
                    <td>{coach.month}</td>
                    <td>{coach.program}</td>
                    <td>{`$${coach.revenue.toFixed(2)}`}</td>
                    <td>{`$${coach.ind_commission.toFixed(2)}`}</td>
                    <td>{`$${coach.team_commission.toFixed(2)}`}</td>
                    <td>{`$${coach.profit_before_sessions.toFixed(2)}`}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        {payroll_breakdown && payroll_breakdown.length === 0 && (
          <div className="data-title">No Data Available</div>
        )}
      </Row>

      <Row style={{ marginTop: "2rem" }}>
        <div className="expenses-title">TOTALS</div>
        <Table
          striped
          variant="dark"
          className="expenses-table"
          responsive
          style={{ marginTop: "1rem" }}>
          <thead>
            <tr>
              <th onClick={() => sortColumn("sales_rep_sum")}>Sales Rep</th>
              <th onClick={() => sortColumn("sum_of_revenue")}>Sum of Revenue</th>
              <th onClick={() => sortColumn("sum_of_ind_commission")}>Sum of Ind. Commission</th>
              <th onClick={() => sortColumn("sum_of_team_commission")}>Sum of Team</th>
              <th onClick={() => sortColumn("sum_of_profit_beore_session")}>
                Sum of Profit Before Sessions
              </th>
            </tr>
          </thead>
          <tbody>
            {sumData &&
              sumData.map((coach, i) => {
                return (
                  <tr key={`{${coach.sales_rep}${i}`}>
                    <td>{coach.sales_rep}</td>
                    <td>{`$${coach.revenue.toFixed(2)}`}</td>
                    <td>{`$${coach.ind_commission.toFixed(2)}`}</td>
                    <td>{`$${coach.team_commission.toFixed(2)}`}</td>
                    <td>{`$${coach.profit_before_sessions.toFixed(2)}`}</td>
                  </tr>
                );
              })}
            <tr>
              {data && data.grand_totals && (
                <>
                  <td style={{ fontWeight: "bold" }}>GRAND TOTALS</td>
                  <td style={{ fontWeight: "bold" }}>{data.grand_totals.sum_of_revenue}</td>
                  <td style={{ fontWeight: "bold" }}>{data.grand_totals.sum_of_ind_commission}</td>
                  <td style={{ fontWeight: "bold" }}>{data.grand_totals.sum_of_team_commission}</td>
                  <td style={{ fontWeight: "bold" }}>
                    {data.grand_totals.sum_of_profit_before_sessions}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </Table>
      </Row></>)}
    </div>
  );
};

export default AdminPayrollBreakdownPage;
