import React from "react";
import { Form } from "react-bootstrap";

const FormSelect = ({
  selector,
  options,
  value,
  handleChange,
  defaultVal = "Select One",
  required
}) => {
  return (
    <Form.Select value={value} onChange={(e) => handleChange(selector, e)} required={required}>
      <option value="">{defaultVal}</option>
      {options.map((option, i) => {
        return (
          <option key={i} value={option.value}>
            {selector === "Would you like to incorporate the Christian faith into your training?"
              ? option.label
              : option.value}
          </option>
        );
      })}
    </Form.Select>
  );
};

export default FormSelect;
